@import "~styles/variables";

.footer {
  margin: auto;
  max-width: $regularWidth;

  font-size: 85%;
  color: #7e7e7e;

  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;

  .copyContainer {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .links {
    > div {
      display: inline;
    }
    margin-right: 10px;

    svg {
      height: 22px;
      margin-left: 6px;
    }
  }

  @media only screen and (max-width: 1260px) {
    flex-direction: column-reverse;
    .links {
      display: flex;
      margin-top: 16px;
      margin-bottom: 30px;
      justify-content: center;
    }
    .copyContainer {
      justify-content: center;
      margin-left: 0 !important;
    }
  }
}
