$menuBackColor: rgba(255, 255, 255, 0.3);

.mainMenu {
  position: fixed;
  text-align: left;
  color: #5a5959;
  z-index: 2;
  top: 0;
  bottom: -0.5px;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;

  .menuOverlay {
    min-height: 100vh;
    width: 100vw;
  }

  .menuItemsBody {
    margin-top: 100px;
    // margin-left: -34px;
    margin-left: 16px;
    max-width: 170px;
    font-weight: 600;
  }

  li {
    margin-bottom: 6px;
    font-size: 26px;
  }
  .links {
    position: fixed;
    bottom: 60px;
    font-weight: 200;
    font-size: 28px;
    display: flex;
    left: 0;
    margin-left: 60px;
    flex-direction: column;

    svg {
      margin-right: 18px;
      margin-top: 8px;
      width: 26px;
      height: auto;
    }
  }

  &.menuHidden {
    max-height: 100px;
    .menuOverlay {
      height: auto;
      min-height: 100px;
      max-height: 100px;
      width: auto;
    }
    .menuContent {
      display: none;
    }
  }

  .menuBtnContainer {
    display: flex;
    // align-items: center;

    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
    padding-top: 30px;
    margin-left: 10px;

    > div {
      background-color: $menuBackColor;
    }

    .menuContent {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      margin-top: 12px;

      .menuTxt {
        padding-left: 10px;
      }
      ul {
        list-style-type: none;
      }
    }
  }

  &.selected {
    background-color: initial;

    background-color: rgba(255, 255, 255, 0.95);

    .menuContent {
      transition: opacity 0.6s ease-in-out;
      opacity: 1;
    }
  }
}
