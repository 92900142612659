@import "~styles/variables";

.regularPage {
  margin: auto;
  width: $regularWidth;
  padding-top: 60px;

  position: relative;
  max-width: 100vw;

  @media (max-width: 1260px) {
    padding-top: 120px;
  }
}
