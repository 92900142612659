$menuBackColor: rgba(255, 255, 255, 0.8);

.mainMenu {
  position: fixed;
  top: 30px;
  text-align: left;
  margin-left: 40px;
  color: #5a5959;
  z-index: 2;
  background-color: $menuBackColor;

  .linkToHome {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      opacity: 0.5;
      &:hover {
        transition: all 0.5s ease-in-out;
        opacity: 1;
      }
      a {
        text-transform: capitalize;
        font-weight: 500;
      }
    }
  }

  .secondLevel {
    margin-left: 170px;
    position: absolute;
    width: 220px;
    margin-top: -20px;
    display: none;
    background-color: $menuBackColor;
    &:hover {
      display: block;
    }

    li {
      padding: 2px 0px;
      // a {
      //   text-transform: capitalize;
      //   font-weight: 500;
      // }
    }
  }
}

.liTitle {
  width: 170px;
  display: block;
  padding: 2px 0px;
  // &:hover {
  //   // color: blue;
  //   transition: all 0.5s ease-in-out;
  //   opacity: 1;
  // }
  &:hover + ul {
    display: block;
    li {
      // background-color: $menuBackColor;
    }
  }
}
