.routerIndexHeader {
  max-width: 680px;
  display: flex;
  align-content: stretch;
  justify-content: space-around;
  flex-direction: column;
  margin-left: auto;

  .name {
    // margin-top: -50px;
    font-size: 90%;
    font-weight: 200;
    margin-bottom: 50px;
  }
  .title {
    margin-bottom: 50px;
    // text-transform: uppercase;
    max-width: 640px;
    font-weight: 700;
    font-size: 15px;
    word-spacing: 0.8px;
    line-height: 150%;
    max-width: 90%;
  }

  @media only screen and (max-width: 1260px) {
    width: 90%;
    margin: 0 auto;

    .subTitle {
      line-height: 168%;
    }
  }
}
