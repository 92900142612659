.asset {
  max-width: 240px;
}

.item {
  margin-bottom: 40px;
  .name {
    border-bottom: 1.5px solid #5e5e5e;
    display: inline-block;
    padding: 4px 4px 2px 0px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .title {
    font-size: 14px;
    max-width: 96%;
  }
}
