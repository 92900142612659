.header {
  margin-top: 100px;
  font-size: 90%;
  font-weight: 200;
  margin-bottom: 20px;
}

.something {
  font-size: 63px;
  color: #8f8d8d;
  text-transform: uppercase;
  line-height: 140%;
  max-width: 230px;
  font-weight: 100;
}
