.regPage {
  margin: 0 auto 0;
  max-width: 860px;
}

@media (max-width: 1260px) {
  .regPage {
    max-width: 100%;
    overflow: scroll;
  }
}
.widePage {
  margin: 0 auto 0;
  max-width: 1200px;
}

.title {
  // color: theme.redMain;
  color: red;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;

  line-height: 150%;

  @media only screen and (max-width: 1260px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

.darkTitle {
  // color: theme.redMain;
  color: rgb(56, 56, 56);
  font-weight: 400;
  font-size: 45px;
  margin-bottom: 3rem;
}

.subTitle {
  font-weight: 600;
  max-width: 1260px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 20px;
  &.smallScreen {
    padding: 10px 15px;
  }
}

.topDescription {
  font-size: 90%;
  font-weight: 200;
  margin-bottom: 50px;
  max-width: 800px;
  max-width: 540px;
  line-height: 150%;
  @media only screen and (max-width: 1260px) {
    width: 90%;
    margin: 0 auto;
  }
}

.redBold {
}
.block {
  @media only screen and (max-width: 1260px) {
    width: 90%;
    margin: 0 auto;
  }
}

.mediumP {
  font-size: 14px;
  color: #565656;
  padding: 18px 0;
  letter-spacing: 0.4px;
  font-weight: 300;
  line-height: 130%;
  max-width: 620px;

  &.smallScreen {
    padding: 10px 15px;
  }
}

.sideComment {
}

.linkBtn {
  margin: 40px 0;
  a {
    transition: all 0.3s linear;
    font-weight: 800;
    border: 2px solid #7b736f;
    padding: 8px 16px;
    color: #7b736f;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    background-color: white;
    &:hover {
      color: white;
      background-color: #7b736f;
    }
  }
}
