.asset {
  margin: auto;
  max-width: 100%;
  height: auto;
}

.oneInRow {
  display: flex;
  flex: 0 0 100%;
  margin-bottom: 60px;
}

.smallScreenImg {
  margin-top: 1em;
}
